@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

:root {
  --primary-color: #930a0d;    
  --secondary-color: #016188;  
  --text-color: #333333;        
  --bg-color: #ffffff;          
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: white;
  font-family: 'Poppins', sans-serif;
}

@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}

@keyframes feedbackAnimation {
  0% { opacity: 0; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
}

.feedback-message {
  animation: feedbackAnimation 1s ease-out;
}
/* Add this to your CSS */
/* Add this CSS to your global stylesheet */
body {
  overflow-x: hidden;
}



/* sabelo */

.slideshow-container {
  position: relative;
  width: 80%;
  max-width: 80%; 
  height: auto; /* Allow height to adjust based on content */
  margin: 50px auto; 
  text-align: center; 
  overflow: hidden; 
}

.slideshow-image {
  width: 100%; 
  height: auto; /* Maintain aspect ratio */
  max-height: 80vh; /* Maintain max height */
  object-fit: contain; /* Change to contain to prevent cropping */
  border-radius: 10px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
}

.prev-button, .next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 40px; 
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8); 
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 

  border: none; 
  border-radius: 50%; 
  width: 50px; 
  height: 50px; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  background: transparent; 
  transition: background 0.3s; 
}

.prev-button {
  left: 20px;
}

.next-button {
  right: 20px;
}

.prev-button:hover, .next-button:hover {
  background: rgba(0, 0, 0, 0.5); 
}

/* Add media query for mobile layout */
@media only screen and (max-width: 768px) {
  .slideshow-image {
    max-height: 60vh; /* Adjust max-height for mobile */
  }
  .prev-button, .next-button {
    font-size: 30px; 
    width: 40px; 
    height: 40px; 
  }
}

/* Add this to your CSS or Tailwind setup */
.celebration-bubble {
  position: absolute;
  border-radius: 50%;
  background-color: #4caf50; /* Green color for achievement */
  animation: bubble-animation 3s infinite;
}

@keyframes bubble-animation {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-50px) scale(1.2);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.bubble-1 { width: 30px; height: 30px; top: 20%; left: 10%; animation-delay: 0s; }
.bubble-2 { width: 40px; height: 40px; top: 50%; left: 70%; animation-delay: 1s; }
.bubble-3 { width: 20px; height: 20px; top: 70%; left: 40%; animation-delay: 2s; }








/* Bubble Animation */
@keyframes bubbleAnimation {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(-50px) scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 0;
  }
}

.bubble-animation {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: bubbleAnimation 3s infinite ease-in-out;
  opacity: 0.7;
  animation-duration: calc(3s + (random() * 3) + 's'); /* Random bubble duration */
  background-color: var(--random-color); /* Color applied dynamically */
}

/* IoT slide background as frosted glass */
.frosted-glass {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px); /* Frosted glass effect */
  border-radius: 10px;
  padding: 40px;
}

/* Confetti Animation */
@keyframes confettiFall {
  0% {
    transform: translateY(-100vh) rotate(0);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}

/* Confetti pieces */
.confetti-piece {
  position: absolute;
  width: 10px;
  height: 10px;
  animation: confettiFall 3s infinite ease-in-out;
  background-color: #ffeb3b;
}

.confetti-piece:nth-child(odd) {
  background-color: #4caf50;
}

.confetti-piece:nth-child(even) {
  background-color: #2196f3;
}


